import { useSelector } from "react-redux";
import { Box, Button } from "../../components/StyledComponent";
import Showcase from "./Showcase/Showcase";
import { isIOS } from "../../util";

export default function Home() {
  function handleClick() {
    window.open('https://waterparks.lnk.to/SOOH');
  }

  function handleRedirect() {
    window.location.assign('https://waterparksband.com');
  }

  return (
    <Box className="text-white h-screen w-full flex flex-col justify-center items-center">
      <Showcase />
      <div
        className={`z-30 fixed bottom-1 md:bottom-5 flex flex-col md:flex-row`}
      >
        <Button onClick={handleClick} className="mb-1 md:mb-0 md:mr-2">
          PRE-SAVE
        </Button>
        <Button onClick={handleRedirect} className="md:ml-2">
          ENTER WEBSITE
        </Button>
      </div>
    </Box>
  );
}
