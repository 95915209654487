import { Box, Button } from "../../../components/StyledComponent";
import { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import BG_DESKTOP_IMG from "../../../assets/images/Waterparks hero desktop-new.png";
import BG_MOBILE_IMG from "../../../assets/images/Waterparks hero mobile-new.png";
import ImageMapper from 'react-img-mapper';
import { isMobile } from "../../../constants";
import { isIOS } from "../../../util";

import DATA_DESKTOP from './mapping-desktop.json';
import DATA_MOBILE from './mapping-mobile.json';

const Showcase = () => {
  const [imgsIsLoading, setImgsIsLoading] = useState(true);
  const [isLoaded, setIsLoaded] = useState(false);
  const [playing, setIsPlaying] = useState<boolean>(false);
  const [audio] = useState(new Audio());
  const musicList = ['P2-001', 'P2-002', 'P2-003', 'P2-004', 'P2-005', 'P2-006', 'P2-007', 'P2-008', 'P2-009', 'P2-010'];

  const MAP = {
    name: 'waterparks-map',
    // GET JSON FROM BELOW URL AS AN EXAMPLE
    areas: (isMobile ? DATA_MOBILE : DATA_DESKTOP),
  };

  const reloadWindowIfResized = () => {
    // setTimeout(function() {
      window.location.reload();
    // }, 500);
  }

  useEffect(() => {
    if (!isMobile) {
      window.addEventListener('resize', reloadWindowIfResized);
      return () => {
        window.removeEventListener('resize', reloadWindowIfResized)
      }
    }
  }, []);

  useEffect(() => {
    const preloadSrcList: string[] = [
      BG_DESKTOP_IMG,
      BG_MOBILE_IMG,
    ];

    cacheImages(preloadSrcList);
  }, [imgsIsLoading]);

  const cacheImages = async (srcArray: any) => {
      const promises = await srcArray.map((src: any) => {
          return new Promise((resolve, reject) => {
              const img = new Image();
              // console.log(src);

              img.src = src;
              img.onload = () =>
                  setTimeout(() => {
                      resolve(img)
                  }, 2000);
              
              if (!img.onerror) {
                setImgsIsLoading(false);
              }

              img.onerror = err => reject(err)
          });
      });
      console.log('imgsIsLoading', imgsIsLoading);

      await Promise.all(promises);

      setImgsIsLoading(false);

      // console.log(imgsIsLoading);
  };

  useEffect(() => {
    musicList.sort(() => Math.random() - 0.5);
    // console.log(musicList);
  }, [])

  function loadMusic(musicIdx: any) {
    audio.src = `./songs/${musicList[musicIdx]}.mp3`;
    audio.play();
  }

  return (
    <Box
      className="relative flex flex-col justify-start md:justify-center items-center h-screen w-full overflow-hidden touch-none"
    >
      <Box className="z-30 flex flex-col justify-start md:justify-center items-center h-full w-full">
        <div
          className="h-auto lg:h-full w-full flex align-center justify-center -ml-8 md:ml-0 md:-mt-28 scale-[1.1] md:scale-100"
        >
          <ImageMapper
            src={isMobile ? BG_MOBILE_IMG : BG_DESKTOP_IMG}
            responsive={true}
            parentWidth={window.innerWidth}
            map={MAP}
            stayHighlighted={false}
            onMouseEnter={(area: any, index: any, event): void => {
              // console.log(area);
            }}
            onMouseLeave={() => {
              // bubble.style.display = 'none';
            }}
            onClick={(area: any, index: any, event): void => {
              console.log(area, index);
              loadMusic(index);
            }}
            onImageClick={(event): void => {
              
            }}
            onLoad={(imageRef: any, parentDimensions: { width:any, height:any }): void => {
              // console.log(imageRef, parentDimensions);
            }}
            onImageMouseMove={(event): void => {
              // console.log(event);
            }}
          />
        </div>
      </Box>
    </Box>
  );
};

export default Showcase;
