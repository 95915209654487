import { ButtonHTMLAttributes, HtmlHTMLAttributes } from "react";

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  filler?: boolean;
  className?: string;
};

const Button = ({ className, ...props }: ButtonProps) => {
  return (
    <button
      className={`border-2 bg-black text-[#72A11F] border-black hover:bg-transparent hover:text-black py-1 md:py-2 px-10 md:px-12 font-lucida-grande uppercase whitespace-nowrap text-sm lg:text-lg ${className}`}
      {...props}
    >
      {props.children}
    </button>
  );
};

export default Button;
